<template>
  <div class="Document">
    <div class="main">
      <div><b><label style="font-size: 24px;">{{ document.title }}</label></b></div>
      <div><a-icon type="user" /> {{ document.author }}</div>
      <div><a-icon type="clock-circle" /> {{ document.updatedAt }}</div>
      <a-divider />
      <div v-if="document.html">
        <a-spin tip="加载中..." :spinning="spinning">
          <iframe ref="iframe" :src="'./html/' + document.html" frameborder="0" scrolling="no" class="content"></iframe>
        </a-spin>
      </div>
    </div>
    <div class="footer">
      <a-row type="flex" align="middle">
        <a-col flex="64px" style="text-align: center; margin-left: -8px;" @click="goHome()">
            <a-icon type="home" style="font-size: 24px; "/>
            <br/>
            <span style="font-size: 12px;">主页</span>
        </a-col>
        <a-col flex="auto">
          <Contact pageName="Document" :id="document.id"/>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import Contact from "@/components/Contact.vue";
import data from "@/data.js";
export default {
  name: "Document",
  components: {
    Contact,
  },
  beforeRouteEnter(to, from, next) {
    // 加载数据
    next((vm) => {
      var doc = data.list.find((item) => item.id == to.params.id);
      if (doc == undefined) {
        vm.$router.replace({
          path: "/",
        });
      } else {
        vm.document = doc;
      }
    });
  },
  methods: {
    goHome() {
      this.$pushCNZZ.event(
        '回主页',
        'Home页回主页',
        this.document.id
      );
      this.$router.push({
        path: "/"
      });
    },
    stateChange(iframe) {
      iframe.height = iframe.contentWindow.document.documentElement.scrollHeight
      this.spinning = false
    }
  },
  data() {
    return {
      document: {
        title: '',
        author: '',
        updatedAt: '',
        html: ''
      },
      spinning: true
    };
  },
  mounted() {
    this.$nextTick(() => {
      const iframe = this.$refs.iframe;
      // IE和非IE浏览器，监听iframe加载事件不一样，需要兼容
      const that = this;
      if (iframe.attachEvent != undefined) {
        // IE
        iframe.attachEvent('onload', () => {
          that.stateChange(iframe);
        });
      } else {
        // 非IE
        iframe.onload = function () {
          that.stateChange(iframe);
        };
      }
    })
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  padding: 16px;
}
.content {
  width: 100%;
  margin-bottom: 80px;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 8px;
  /* 兼容iPhoneX */
	padding-bottom: 30px;
  background-color: #ededed;
}
</style>
